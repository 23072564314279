import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import Section from '../layout/section'
import Button from '../components/button'
import SeloImg from '../images/home/selo.png'
import './products_and_services.css'

const ProductsAndServices = () => {
  return (
    <Layout>
      <Helmet>
        <title>Motorbooks - Produtos e Serviços</title>
      </Helmet>
      <Section id='temperature-sticker' isDark>
        <h1>Selo de Registro de Temperatura Motorbooks</h1>
        <div className='product-image'>
          <img src={SeloImg} width={500} />
        </div>
        <div className='align-left product-text'>
          <p className='blue-text'>
          Nosso objetivo é sempre garantir a proteção do Retificador, portanto fizemos uma extensa
          pesquisa e nos baseamos na NBR ABNT 13032, que diz ser obrigatório a aplicação do Selo de Registro
          de Temperatura nos motores reinstalados nas retíficas de motores.
          </p>
          <p>
          Buscamos, então, no mercado internacional o que melhor nos garante em relação a precisão de
          monitoramento dessa temperatura.
          </p>
          <p className='blue-text'>
          O Selo de Registro de temperatura MotorBooks é seguro, fabricado nos EUA com o melhor
          padrão de qualidade, inclusive homologado por importantes fabricantes de motores na linha automotiva,
          aeronáutica e náutica, geradores e afins. Possui uma escala que escurecerá e registrará a temperatura
          máxima atingida nos mostrando se o motor excedeu o limite devido mau uso, ou problemas na sua
          reinstalação ou sistema de refrigeração ineficiente.
          </p>
          <p className='bold-text'>
          Proteja-se de possíveis garantias e ações judiciais!
          </p>
          <Button href='https://api.whatsapp.com/send?phone=5511988924847' text='Solicite um orçamento através de nossos canais de atendimento!' />
        </div>
      </Section>
    </Layout>
  )
}

export default ProductsAndServices
