import React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Button = ({ text, isDark = false, linkTo, href, anchorTo}) => {
  const button = (
      <div className='button' style={{...buttonStyles, ...(isDark ? darkButtonStyles : [])}}>
          {text}
      </div>
  )

  let component = (
    <Link to={linkTo} style={{ color: '#F3F3F3' }}>
      {button}
    </Link>
  )

  if (href !== undefined) {
    component = (
      <a href={href} style={{ color: '#F3F3F3' }}>
        {button}
      </a>
    )
  }

  if (anchorTo !== undefined) {
    component = (
      <AnchorLink to={`${linkTo}${anchorTo}`} style={{ color: '#F3F3F3' }}>
        {button}
      </AnchorLink>
    )
  }

  return component
}

const buttonStyles = {
  backgroundColor: '#2967B0',
  display: 'inline-block',
  color: '#F3F3F3',
  borderRadius: '50px',
  textAlign: 'center',
  padding: '5px 10px'
}

const darkButtonStyles = {
  backgroundColor: '#2B3351'
}

export default Button
