import React from 'react'
import { useInView } from 'react-intersection-observer'
import './section.css'

const Section = ({ id, isDark = false, children }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  })

  return (
    <section ref={ref} id={id} className={`section ${inView === true ? 'slide-up-animation' : ''}`}style={{ backgroundColor: isDark ? '#191F31' : '#FAFAFA' }}>
      {children}
    </section>
  )
}

export default Section
